/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import { RewardsActivityItem } from './RewardsActivityItem';

export function RewardsActivityContent() {
  const { data: rewardsData } = useRewardsActivity();
  let rewardsActivityLineItems = rewardsData?.rewardsActivityLineItems ?? []; //limit to only 4 most recent items

  if (rewardsActivityLineItems.length > 4) {
    rewardsActivityLineItems = rewardsActivityLineItems.slice(0, 4);
  }

  return (
    <>
      {rewardsActivityLineItems.map((item, i) => (
        <RewardsActivityItem
          key={`${item.transactionNumber}_${i}`} //adding the index to transaction number to ensure key is unique. If customer uses and gains rewards in same transaction, can cause duplicate key issue.
          content={item}
        />
      ))}
    </>
  );
}
