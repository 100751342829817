/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';
import { Grid } from '@/components/Grid';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import azCommonStyles from '@/theme/globals.module.scss';
import { formatDate } from '@/utils/date/formatDate';
import { TIMEUTCFORMAT } from '@/utils/validatorRegex';
import { Skeleton } from '@az/starc-ui';
import cx from 'classnames';
import styles from '../../styles.module.scss';

type AvailableBalanceProps = {
  nextRewardExpDate: string | null | undefined;
  rewardBalance: number | undefined;
  nextRewardExpAmount: number | undefined;
  isLoading: boolean;
};

const labelMap = {
  lblAvailableRewardsBalance: 'label_MyAccountMyProfile_landing_AvailableRewardsBalance',
  lblRewardExpiresOn: 'label_MyAccountMyProfile_landing_RewardExpiresOn',
  lblRewardTooltipWhatsThis: 'label_MyAccountMyProfile_landing_Tooltip_WhatsThis',
  lblTooltipInfoAvailableBalance: 'label_MyAccountMyProfile_landing_Tooltip_Info_AvailableBalance',
  lblRewardYour: 'label_MyAccountMyProfile_landing_RewardYour',
};

export function AvailableBalance({
  nextRewardExpDate,
  rewardBalance,
  nextRewardExpAmount,
  isLoading,
}: AvailableBalanceProps) {
  const labels = useLabels(labelMap);
  const locale = useLocale();

  const nextRewardExpDateWithTimezone =
    nextRewardExpDate && !nextRewardExpDate.toString().match(TIMEUTCFORMAT)
      ? nextRewardExpDate + 'T00:00:00'
      : nextRewardExpDate?.toString();

  return (
    <Grid item lg={4} md={6} sm={12}>
      <div
        className={styles.rewardsCardContainer}
        tabIndex={0}
        aria-label={`$${rewardBalance ? rewardBalance.toFixed(2) : '0.00'} ${
          labels.lblAvailableRewardsBalance
        }`}
      >
        <div>
          <div
            className={cx(styles.availableBalanceAmount, azCommonStyles['az-margin-bottom-xxs'])}
            data-testid="available_balance"
          >
            {!isLoading ? (
              `$${rewardBalance ? rewardBalance.toFixed(2) : '0.00'}`
            ) : (
              <Skeleton height="45px" width="100px" borderRadius="small" />
            )}
          </div>
          <div className={styles.availableBalanceText}>
            {labels.lblAvailableRewardsBalance}
            <InfoToolTip
              classes={{
                icon: styles.tooltipInfo,
              }}
              header={labels.lblRewardTooltipWhatsThis}
              body={labels.lblTooltipInfoAvailableBalance}
            />
          </div>
          {rewardBalance !== 0 && nextRewardExpDate && nextRewardExpDateWithTimezone && (
            <div
              data-testid="ms_next_reward_expiry"
              className={cx(
                styles.nextRewardExpiry,
                azCommonStyles['az-body-2-regular'],
                azCommonStyles['az-margin-top-s']
              )}
            >
              {`${labels.lblRewardYour} $${(nextRewardExpAmount ?? 0).toFixed(2)}
            ${labels.lblRewardExpiresOn}`}
              <strong>{formatDate(nextRewardExpDateWithTimezone, locale)}</strong>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}
