/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentBlockSlot, NotSpecifiedContentBlock } from '@/types/legacy';

export const isContentBlockSlot = (
  item: ContentBlockSlot | Omit<NotSpecifiedContentBlock, 'contentType'>
): item is ContentBlockSlot => {
  return 'contents' in item && item.contents !== undefined && 'length' in item.contents;
};
