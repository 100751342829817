/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { Label } from '@/features/i18n';
import azCommonStyles from '@/theme/globals.module.scss';
import { vehicleIcon } from '@/constants/images/vehicleIcon';
import NextImage from '@/components/NextImage';
import type { VehicleProps } from '@/features/ymme';
import { useVehiclesMutation } from '@/features/ymme/api/postVehicles';
import { ymmeActionSources } from '@/features/ymme/utils/ymmeHelpers';
import { setInteractionLocation } from '@/features/ymme/utils/ymmeLocationHelper';
import { setYmmeActionSource } from '@/features/ymme/utils/ymmeActionSourceHelper';
import styles from './styles.module.scss';

type VehicleCardProps = {
  vehicle: VehicleProps;
  setVehiclesByVin: (a: VehicleProps[]) => void;
};

const VehicleCard = ({ vehicle, setVehiclesByVin }: VehicleCardProps) => {
  const { mutate: ymmeAddVehicle } = useVehiclesMutation();

  const addVehicle = () => {
    setInteractionLocation('Enter VIN');
    setYmmeActionSource(ymmeActionSources.vinLookup);
    ymmeAddVehicle({ vehicleId: vehicle.vehicleId });
    setVehiclesByVin([]);
  };

  return (
    <div className={styles.vehicleCardContainer}>
      <NextImage width={22} height={16} src={vehicleIcon.src} alt={vehicleIcon.alt} />
      <div className={styles.vehicleInfoContainer}>
        <div
          className={cx(azCommonStyles['az-body-2-regular'], styles.vehicleCardDisplayName)}
          data-testid="no-selected-vehicle"
        >
          {vehicle.vehicleDisplayName}
        </div>
        <div
          className={styles.linkText}
          onClick={addVehicle}
          data-testid="set-as-current-vehicle-button"
        >
          <Label label="label_MyAccountMyProfile_vehicle_SetAsCurrentVehicle" />
        </div>
      </div>
    </div>
  );
};

export default VehicleCard;
