/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const labelMap = {
  lblHome: 'label_Footer_main_Home',
  breadcrumb: 'label_VinDecoder_Breadcrumb',
  searchButton: 'label_VinDecoder_Search_Button',
  searchPlaceholder: 'label_VinDecoder_Search_Placeholder',
  vinValidationError: 'label_VinDecoder_Validation_Error',
  vinDecoderOneMatch: 'label_vin_decoder_one_match',
  vinDecoderMultipleMatches: 'label_vin_decoder_multiple_matches',
  vinDecoderNoMatches: 'label_vin_decoder_no_matches',
};
