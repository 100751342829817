/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import { isMobile } from '../../../utils/common';
import { rewardsConstants } from '../../../constants/rewards';
import type { RewardsActivityLineItem } from '../../../types/rewards';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { RewardsBalanceActivity } from './RewardsBalanceActivity';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
type Props = {
  content: RewardsActivityLineItem;
};
const labelMap = {
  lblpostedOn: 'label_MyAccountMyProfile_orderhistory_PostedOn',
  lblinStoreReturn: 'label_MyAccountMyProfile_orderhistory_InStoreReturn',
  lblrewardsAdjustment: 'label_MyAccountMyProfile_orderhistory_RewardsAdjustment',
  lblcreditRequest: 'label_MyAccountMyProfile_orderhistory_RewardsCreditRequest',
  lblrewardsCredit: 'label_MyAccountMyProfile_orderhistory_RewardsCredit',
  lblcreditAdjustment: 'label_MyAccountMyProfile_orderhistory_CreditAdjustment',
};

export function RewardsActivityItem({ content }: Props) {
  const labels = useLabels(labelMap);
  const isReturn = content?.description === rewardsConstants.rewardsActivity.Return;
  const [adjustment, request] = content.orderType.split('-');
  const { data: rewardsData } = useRewardsActivity();
  const loyaltyAccount = rewardsData?.loyaltyAccount;
  return (
    <Grid item lg={6} md={12} sm={12}>
      <div
        data-testid="rewards_activity_rewardearndate"
        className={(azCommonStyles['az-margin-bottom-xs'], styles.rewardsActivityCard)}
      >
        <div className={styles.contentPanel}>
          <div className={cx(azCommonStyles['az-body-2-heavy'], styles.postedContent)}>
            <div>
              {!isMobile() ? `${labels.lblpostedOn} ` : ''}
              {(content?.rewardsActivityLineItemDetail?.rewardEarnDate ?? '').split(' ')[0]}
            </div>
            <div>#{content.transactionNumber}</div>
          </div>
          <div className={cx(azCommonStyles['az-body-1-regular'], styles.orderPanel)}>
            <Grid container className={styles.rewardsDetail}>
              <Grid item xs={6}>
                <div>
                  {isReturn ? (
                    labels.lblinStoreReturn
                  ) : content.description === rewardsConstants.adjustment ? (
                    <div className={styles.rewardsEntry}>{labels.lblrewardsAdjustment}</div>
                  ) : (
                    <div className={styles.rewardsEntry}>{adjustment}</div>
                  )}
                </div>
                <div>
                  {!isReturn && (
                    <div className={styles.rewardsEntryTwo}>
                      {content.description === rewardsConstants.adjustment
                        ? labels.lblcreditRequest
                        : request}
                    </div>
                  )}
                </div>
              </Grid>
              {loyaltyAccount && (
                <Grid item xs={6}>
                  <div className={cx(azCommonStyles['az-body-1-heavy'], styles.orderRewards)}>
                    <div>
                      {content.creditActivity > 0
                        ? `${labels.lblrewardsCredit} +${content.creditActivity}`
                        : isReturn
                        ? labels.lblcreditAdjustment
                        : ``}
                    </div>
                    {content.rewardActivity && !isReturn ? (
                      <RewardsBalanceActivity activity={content.rewardActivity} />
                    ) : null}
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </Grid>
  );
}
