/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Grid } from '@/components/Grid';
import { routePaths } from '@/constants/routePaths';
import { useLabels } from '@/hooks/useLabels';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import { getDecodedCookies } from '@/utils/cookie';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Skeleton } from '@az/starc-ui';
// @ts-expect-error missing type definitions
import Barcode from 'react-barcode';
import { AvailableBalance } from './components/AvailableBalance/AvailableBalance';
import { CreditsEarned } from './components/CreditsEarned/CreditsEarned';
import { RewardsLink } from './components/RewardsLink/RewardsLink';
import styles from './styles.module.scss';

const barcodeProps = {
  width: 2,
  height: 59,
  format: 'CODE128',
  displayValue: false,
};

const links = {
  rewardsMissingCredit: {
    linkTo: routePaths.userRewardsRequestCredit,
    dataTest: 'link-request-credit',
    ariaLabel: 'open rewards request credit',
  },
  rewardsCustomerService: {
    linkTo: routePaths.contactusRewards,
    dataTest: 'link-customer-service',
    ariaLabel: 'open rewards customer service',
  },
  rewardsTermsConditions: {
    linkTo: routePaths.termsConditionsRewardsProgramRules,
    dataTest: 'link-terms-conditions',
    ariaLabel: 'open rewards terms and conditions',
  },
};

const labelMap = {
  lblMembershipStatus: 'label_Rewards_MembershipStatus',
  lblRewardsLinkMissingCredit: 'label_MyAccountMyProfile_rewards_links_MissingCredit',
  lblRewardsLinkCustomerService: 'label_Rewards_link_Customer_Service',
  lblRewardsLinkTermsAndConditions: 'label_MyAccountMyProfile_rewards_link_TermsAndConditions',
  lblMemberShipId: 'label_MyAccountMyProfile_rewards_RewardsMembershipId',
};

type Props = {
  isFromCMS?: boolean;
};

export const MembershipStatusBlock = ({ isFromCMS }: Props) => {
  const labels = useLabels(labelMap);
  const { data: rewardsData, isLoading } = useRewardsActivity();
  const [loyaltyCardNumber, setLoyaltyCardNumber] = useState<string | undefined>();

  useEffect(() => {
    if (rewardsData?.loyaltyAccount.loyaltyCardNumber) {
      setLoyaltyCardNumber(rewardsData.loyaltyAccount.loyaltyCardNumber);
    } else {
      const cookies = getDecodedCookies(['rewardsId']);
      setLoyaltyCardNumber(cookies.get('rewardsId'));
    }
  }, [rewardsData?.loyaltyAccount.loyaltyCardNumber]);

  return (
    <div className={styles.membershipStatus}>
      <div className={styles.headerContainer}>
        <h2
          className={cx(styles.membershipStatusheader, {
            [styles.membershipStatusheaderWithPadding]: !isFromCMS,
            [styles.membershipStatusHeaderCMS]: isFromCMS,
          })}
          data-testid="membership_status_header"
        >
          {labels.lblMembershipStatus}
        </h2>
      </div>
      <Grid
        container
        className={cx(styles.membershipStatusContainer, {
          [styles.membershipStatusContainerWithPadding]: !isFromCMS,
        })}
        spacing={3}
      >
        <CreditsEarned
          nextCredExpDate={rewardsData?.loyaltyAccount.nextCredExpDate}
          currentNumberOfCredits={rewardsData?.loyaltyAccount.currentNumberOfCredits}
          creditsPerReward={rewardsData?.loyaltyAccount.creditsPerReward}
          nextCredExpAmount={rewardsData?.loyaltyAccount.nextCredExpAmount}
        />
        <AvailableBalance
          nextRewardExpDate={rewardsData?.loyaltyAccount.nextRewardExpDate}
          rewardBalance={rewardsData?.loyaltyAccount.rewardBalance}
          nextRewardExpAmount={rewardsData?.loyaltyAccount.nextRewardExpAmount}
          isLoading={isLoading}
        />
        <Grid item lg={4} sm={12}>
          <div
            className={styles.rewardsCardMedContainer}
            tabIndex={0}
            aria-label={`${labels.lblMemberShipId} ${loyaltyCardNumber ?? ''}`}
          >
            <div className={styles.barcodeContainer}>
              <Barcode value={loyaltyCardNumber ? loyaltyCardNumber : '0'} {...barcodeProps} />
            </div>
            <div className={styles.membershipIdContainer}>
              {labels.lblMemberShipId}:
              <div className={styles.loyaltyNumberContainer} data-testid="ms_memership_number">
                {loyaltyCardNumber ?? <Skeleton width="100px" />}
              </div>
            </div>
          </div>
        </Grid>

        <Grid container spacing={0} className={cx(styles.rewardsLinks)}>
          <RewardsLink
            linkTo={links.rewardsMissingCredit.linkTo}
            dataTest={links.rewardsMissingCredit.dataTest}
            ariaLabel={links.rewardsMissingCredit.ariaLabel}
            labelName={labels.lblRewardsLinkMissingCredit}
          />
          <RewardsLink
            linkTo={links.rewardsTermsConditions.linkTo}
            dataTest={links.rewardsTermsConditions.dataTest}
            ariaLabel={links.rewardsTermsConditions.ariaLabel}
            labelName={labels.lblRewardsLinkTermsAndConditions}
          />
          <RewardsLink
            linkTo={links.rewardsCustomerService.linkTo}
            dataTest={links.rewardsCustomerService.dataTest}
            ariaLabel={links.rewardsCustomerService.ariaLabel}
            labelName={labels.lblRewardsLinkCustomerService}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MembershipStatusBlock;
