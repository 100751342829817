/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useState } from 'react';
import { Grid } from '@/components/Grid';
import { useLabels } from '@/hooks/useLabels';
import { VINDecoderForm } from '@/features/ymme/components/VINDecoderForm';
import type { VehicleProps } from '@/features/ymme';
import VehicleCard from './VehicleCard';
import { labelMap } from './labels';
import styles from './styles.module.scss';

export const VinDecoder = () => {
  const labels = useLabels(labelMap);
  const [vehiclesByVin, setVehiclesByVin] = useState<VehicleProps[]>([]);
  const showVehicles = vehiclesByVin.length;

  const onVinLookupSuccess = (vehicles: VehicleProps[]) => {
    setVehiclesByVin(vehicles);
  };

  return (
    <div id="vin-code" className={styles.vinDecoderContainer}>
      <VINDecoderForm onVinLookupSuccess={onVinLookupSuccess} />
      {showVehicles ? (
        <Grid item lg={12} xs={12} md={12} className={styles.vehiclesListContainer}>
          <div className={styles.infoText}>
            {vehiclesByVin.length === 1
              ? labels.vinDecoderOneMatch
              : vehiclesByVin.length > 1
              ? labels.vinDecoderMultipleMatches
              : null}
          </div>
          {vehiclesByVin.map((vehicle: VehicleProps) => (
            <VehicleCard
              vehicle={vehicle}
              key={vehicle.vehicleId}
              setVehiclesByVin={setVehiclesByVin}
            />
          ))}
        </Grid>
      ) : null}
    </div>
  );
};

export default VinDecoder;
