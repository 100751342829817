/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { imageConstant } from '../../../constants/images';
import Hidden from '../../Hidden';
import { Grid } from '../../Grid';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { routePaths } from '../../../constants/routePaths';
import { Button } from '@/components/Button';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import type { DeviceOptions } from '@/types/legacy';
import styles from './styles.module.scss';
import { RewardsActivityContent } from './RewardsActivityContent';

export type Props = {
  deviceTypes: DeviceOptions;
};
const labelMap = {
  lblrewardsActivity: 'label_MyAccountMyProfile_orderhistory_RewardsActivity',
  lblseeAll: 'label_topnav_seeall',
};

export function RewardsActivityBlock({ deviceTypes }: Props) {
  const labels = useLabels(labelMap);
  const router = useRouter();

  const navigateToRewardsTransactions = () => {
    router.push({
      pathname: routePaths.userOrderHistory,
      query: {
        tab: 'rewards',
      },
    });
  };

  return (
    <DeviceWrapper {...deviceTypes}>
      <div className={styles.rewardsActivity}>
        <div className={styles.headerContainer}>
          <h2 className={styles.rewards}>{labels.lblrewardsActivity}</h2>
          <span className={styles.seeAllLink}>
            <Button
              data-testid="see_all_button"
              className={styles.seeAllButton}
              variant="link"
              onClick={navigateToRewardsTransactions}
            >
              {labels.lblseeAll}
            </Button>
          </span>
        </div>
        <Grid container className={styles.rewardsActivityContainer}>
          <RewardsActivityContent />
          <Hidden mdUp>
            <Button
              className={styles.seeAllButton}
              variant="link"
              onClick={navigateToRewardsTransactions}
            >
              <span>{labels.lblseeAll}</span>
              <img src={imageConstant.Forward.src} alt={imageConstant.Forward.alt} />
            </Button>
          </Hidden>
        </Grid>
      </div>
    </DeviceWrapper>
  );
}

export default RewardsActivityBlock;
