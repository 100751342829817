/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Grid } from '@/components/Grid';
import { SmartLink as Link } from '@/utils/smartLink';
import styles from '../../styles.module.scss';

type RewardsLinkProps = {
  linkTo: string;
  dataTest: string;
  ariaLabel: string;
  labelName: string;
};

export const RewardsLink = ({ linkTo, dataTest, ariaLabel, labelName }: RewardsLinkProps) => (
  <Grid item sm={6} md={4} lg={4} className={styles.rewardsLinkContainer}>
    <Link to={linkTo} data-test={dataTest} aria-label={ariaLabel}>
      {labelName}
    </Link>
  </Grid>
);
