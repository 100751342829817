/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
type Props = {
  activity: number;
};
const labelMap = {
  lblrewardsGained: 'label_Rewards_modal_RewardsGained',
  lblrewardsUsed: 'label_Rewards_modal_RewardsUsed',
};

export function RewardsBalanceActivity({ activity }: Props) {
  const labels = useLabels(labelMap);
  const isPositiveBalance = activity > 0;
  const balanceContent = isPositiveBalance ? labels.lblrewardsGained : labels.lblrewardsUsed;
  return (
    <div
      data-testid="rewards_balance_rewards_activity"
      className={cx({
        [styles.redeemedReward]: !isPositiveBalance,
        [styles.gainedReward]: isPositiveBalance,
      })}
    >
      {`${balanceContent}
        ${isPositiveBalance ? '+' : '-'}$${Math.abs(activity).toFixed(2)}`}
    </div>
  );
}
