/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Grid } from '@/components/Grid';
import HeadTemplate from '@/components/PageTemplates/Head';
import { useScrollIntoViewDOM } from '@/hooks/useScrollIntoView';
import { kebabCase } from '@/utils/kebabCase';
import styles from './styles.module.scss';
import { LandingPageGeneric } from './LandingPageGeneric';
import { PreFooter } from '@/features/prefooter';
import type { LandingHead, LandingPageBody, LandingPageBanner } from '@/types/legacy';

type Props = {
  isPreview?: boolean;
  landingPageHead?: LandingHead[];
  landingPageBody?: LandingPageBody[];
  landingPageBanner?: LandingPageBanner[];
  pageName?: string;
};

export const LandingPageTemplate = ({
  isPreview,
  landingPageHead,
  landingPageBody,
  landingPageBanner,
  pageName,
}: Props) => {
  let landingPageHeadComp = null;
  let landingPageBannerComp = null;
  let landingPageBodyComp = null;
  useScrollIntoViewDOM([landingPageBody], false);

  if (landingPageHead?.length) {
    landingPageHeadComp = <HeadTemplate content={landingPageHead} isPreview={isPreview} />;
  }

  if (landingPageBanner?.length) {
    landingPageBannerComp = <LandingPageGeneric content={landingPageBanner} />;
  }

  if (landingPageBody?.length) {
    landingPageBodyComp = <LandingPageGeneric content={landingPageBody} />;
  }

  return (
    <Grid data-testid={pageName ? kebabCase(pageName) : ''} className={styles.wrapper}>
      <Grid data-testid="landing-page-banner-comp">{landingPageBannerComp}</Grid>
      <Grid>{landingPageHeadComp}</Grid>
      <Grid>{landingPageBodyComp}</Grid>
      <PreFooter />
    </Grid>
  );
};
