/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';
import { Grid } from '@/components/Grid';
import NextImage from '@/components/NextImage';
import { imageConstant } from '@/constants/images';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import azCommonStyles from '@/theme/globals.module.scss';
import { formatDate } from '@/utils/date/formatDate';
import { TIMEUTCFORMAT } from '@/utils/validatorRegex';
import cx from 'classnames';
import styles from '../../styles.module.scss';

type CreditsEarnedProps = {
  nextCredExpDate: string | null | undefined;
  currentNumberOfCredits: number | undefined;
  creditsPerReward: number | undefined;
  nextCredExpAmount: number | undefined;
};

const labelMap = {
  lblCreditExpiresOn: 'label_MyAccountMyProfile_landing_CreditExpiresOn',
  lblRewardTooltipWhatsThis: 'label_MyAccountMyProfile_landing_Tooltip_WhatsThis',
  lblTooltipInfoCreditsEarned: 'label_MyAccountMyProfile_landing_Tooltip_Info_CreditsEarned',
  lblCreditsEarned: 'label_MyAccountMyProfile_landing_CreditsEarned',
};

export function CreditsEarned({
  nextCredExpDate,
  currentNumberOfCredits,
  creditsPerReward,
  nextCredExpAmount,
}: CreditsEarnedProps) {
  const labels = useLabels(labelMap);
  const locale = useLocale();

  const nextCredExpDateWithTimezone =
    nextCredExpDate && !nextCredExpDate.toString().match(TIMEUTCFORMAT)
      ? nextCredExpDate + 'T00:00:00'
      : nextCredExpDate?.toString();

  return (
    <Grid item lg={4} md={6} sm={12}>
      <div className={styles.rewardsCardContainer}>
        <div className={styles.bottomContentRewards}>
          <div
            aria-label={`${currentNumberOfCredits ?? 0} out of ${
              creditsPerReward ?? 0
            } credits earned.`}
            className={cx(styles.myAccountRightMidContent)}
            tabIndex={0}
          >
            <NextImage
              data-testid="ms_current_numberofrewards"
              className={cx(styles.speedometer, {
                [styles.imageSkeleton]: currentNumberOfCredits === undefined,
              })}
              src={`${imageConstant.rewardsImage.src}${
                currentNumberOfCredits ? currentNumberOfCredits : 0
              }credits.svg`}
              alt={imageConstant.rewardsImage.alt}
              width={238}
              height={125}
            />
            <div className={styles.creditsEarned}>
              {labels.lblCreditsEarned}
              <InfoToolTip
                classes={{
                  icon: styles.tooltipInfo,
                }}
                header={labels.lblRewardTooltipWhatsThis}
                body={labels.lblTooltipInfoCreditsEarned}
              />
            </div>
            {currentNumberOfCredits !== 0 && nextCredExpDate && nextCredExpDateWithTimezone && (
              <div
                className={cx(styles.nextCreditExpiry, azCommonStyles['az-body-2-regular'])}
                data-testid="ms_next_credit_expiry_date"
              >
                {`${nextCredExpAmount ?? 0} ${labels.lblCreditExpiresOn}`}
                <strong className={styles.nextCreditExpiryDate}>
                  {formatDate(nextCredExpDateWithTimezone, locale)}
                </strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
}
